<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Attendance</h2>
      <r-menu-tab :level="2" :showed-menus="3"></r-menu-tab>
    </template>
    <template #page-content>
      <b-modal v-model="isComponentModalActive" full-screen can-cancel>
        <ActivityDetail
            v-if="selectedAttendance"
          :user-id="selectedUserId"
          :user-name="selectedUserName"
          :date="selectedAttendance.date"
        />
      </b-modal>
      <div id="employee-attendance">
        <div class="columns is-multiline p-0 pb-2 m-0 is-align-items-center">
          <div class="column is-6 p-0">
            <div class="is-flex is-align-items-center">
              <div class="date-picker-container is-align-items-center is-flex">
                <b-datepicker
                  :mobile-native="false"
                  v-model="selectedMonth"
                  @input="selectMonth"
                  class="attendance-datepicker cursor-pointer"
                  type="month"
                >
                  <template v-slot:trigger>
                    <b-button
                      icon-left="calendar-today"
                      type="is-primary"
                      class="attendance-employee-calendar-btn"
                      icon-right="chevron-down"
                    >
                      {{ formatDate(fromDate, 'MMMM YYYY') }}
                    </b-button>
                  </template>
                </b-datepicker>
              </div>

              <div class="chevron-container is-flex is-align-items-center">
                <div class="is-flex-shrink-1">
                  <b-icon
                    icon="chevron-left"
                    @click.native="decreaseMonth"
                    class="cursor-pointer"
                  />
                </div>

                <div class="is-flex-shrink-1">
                  <b-icon
                    icon="chevron-right"
                    @click.native="increaseMonth"
                    class="cursor-pointer"
                  />
                </div>
              </div>

              <div v-if="enableExport">
                <b-dropdown aria-role="list" class="dropdown-generate-report">
                  <template #trigger>
                    <b-button
                      icon-left="file-document-multiple"
                      label="Export"
                      icon-right="chevron-down"
                      type="is-primary"
                      class="export-btn"
                    />
                  </template>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="generateAttendanceReport()"
                  >
                    Export Employee
                  </b-dropdown-item>
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="generateWFOReport()"
                  >
                    Export WFO Report
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>

          <div class="column is-6 p-0">
            <div
              class="columns is-multiline is-flex is-justify-content-flex-end"
            >
              <div class="column is-2-desktop is-12-tablet p-0 m-0">
                <span class="has-text-right-desktop">Working Hour</span>
              </div>
              <div class="column is-2-desktop is-12-tablet p-0 m-0">
                <div class="legend is-normal is-inline-block mr-2"></div>
                <span class="has-text-right-desktop">&lt;8h</span>
              </div>
              <div class="column is-2-desktop is-12-tablet p-0 m-0 is-inline">
                <div class="legend is-overwork is-inline-block mr-2"></div>
                <span>&lt;9h</span>
              </div>
              <div class="column is-2-desktop is-12-tablet p-0 m-0 is-inline">
                <div
                  class="legend extremely-overwork is-inline-block mr-2"
                ></div>
                <span class="has-text-right-desktop">&lt;10h</span>
              </div>

              <div class="column is-2-desktop is-12-tablet p-0 m-0 is-inline">
                <div class="legend burn-out is-inline-block mr-2"></div>
                <span class="has-text-right-desktop">&gt;10h</span>
              </div>
              <div class="column is-2-desktop is-12-tablet p-0 m-0 is-inline">
                <div class="legend time-off is-inline-block mr-2"></div>
                <span class="has-text-right-desktop">Time Off</span>
              </div>
            </div>
          </div>
        </div>

        <div id="table-request-employment" class="table-employee-attendance">
          <b-table
            :data="monthlyAttendance"
            :loading="isLoadingData"
            :bordered="false"
            :sticky-header="stickyHeaders"
          >
            <b-table-column
              field="first_name"
              label="Employees"
              v-slot="props"
              :centered="false"
            >
              <div class="columns is-multiline">
                <div
                  class="column is-1-desktop is-1-tablet p-0 m-0 td-profile-picture-container"
                >
                  <img
                    :src="
                      determineProfilePicture(
                        props.row.profilePicture,
                        props.row.gender
                      )
                    "
                  />
                </div>
                <div class="column pt-0 pb-0 is-flex is-align-items-center">
                  <div>
                    <p class="td-name">
                      {{ props.row.name }}
                    </p>
                    <p class="td-position">
                      {{ props.row.positionName }}
                    </p>
                  </div>
                </div>
              </div>
            </b-table-column>

            <b-table-column
              :label="formatDate(item, 'DD')"
              v-for="item in momentDateRange"
              :key="formatDate(item)"
              v-slot="props"
            >
              <div v-if="getLogByStartDate(formatDate(item), props.row.data)">
                <b-tooltip
                  position="is-bottom"
                  multilined
                  type="is-white"
                  @click.native="
                    getLogByStartDate(formatDate(item), props.row.data)
                      .isTimeOff
                      ? null
                      : selectAttendance(
                          getLogByStartDate(formatDate(item), props.row.data),
                          props.row
                        )
                  "
                >
                  <div
                    :class="
                      getClassCircleMarkWithColor(
                        formatDate(item),
                        props.row.data
                      )
                    "
                  ></div>
                  <template v-slot:content>
                    <div class="columns is-multiline has-text-left">
                      <div class="column is-12">
                        <p>Total working Hour</p>
                        <p class="has-text-weight-bold">
                          {{
                            formatMinute(
                              convertDurationToMinute(
                                getLogByStartDate(
                                  formatDate(item),
                                  props.row.data
                                ).durationPerDay
                              )
                            )
                          }}
                        </p>
                      </div>
                      <div class="column is-6">
                        <p class="has-text-success has-text-weight-bold">
                          First clock in
                        </p>
                        <p>
                          {{
                            getLogByStartDate(formatDate(item), props.row.data)
                              .isTimeOff
                              ? 'Time Off'
                              : formatTime(
                                  getLogByStartDate(
                                    formatDate(item),
                                    props.row.data
                                  ).firstCheckIn,
                                  'DD MMMM YYYY HH:mm'
                                )
                          }}
                        </p>
                      </div>
                      <div class="column is-6">
                        <p class="has-text-danger has-text-weight-bold">
                          Last Clock Out
                        </p>
                        <p
                          v-if="
                            getLogByStartDate(formatDate(item), props.row.data)
                              .lastCheckOut
                          "
                        >
                          {{
                            getLogByStartDate(formatDate(item), props.row.data)
                              .isTimeOff
                              ? 'Time Off'
                              : formatTime(
                                  getLogByStartDate(
                                    formatDate(item),
                                    props.row.data
                                  ).lastCheckOut,
                                  'DD MMMM YYYY HH:mm'
                                )
                          }}
                        </p>
                        <p v-else>On-going</p>
                      </div>
                    </div>
                  </template>
                </b-tooltip>
              </div>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </template>

    <!--    <template #page-sidebar>-->
    <!--      <b-sidebar-->
    <!--        right-->
    <!--        v-model="isSideBarAttendanceOpen"-->
    <!--        fullheight-->
    <!--        id="attendance-sidebar"-->
    <!--        :can-cancel="['escape']"-->
    <!--      >-->
    <!--        <ContentSideBarEmployeeAttendance-->
    <!--          v-model="selectedAttendance"-->
    <!--          @close-sidebar="closeSidebar"-->
    <!--        />-->
    <!--      </b-sidebar>-->
    <!--    </template>-->
  </r-page>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment-timezone'
import { showToast } from '../../services/util'
import axios from 'axios'
import ActivityDetail from './ActivityDetail'

export default {
  components: {
    ActivityDetail,
  },
  props: {
    isLoadAllEmployments: Boolean,
    enableExport: Boolean,
  },
  data() {
    return {
      stickyHeaders: true,

      momentDateRange: [],
      fromDate: moment().startOf('month'),
      toDate: moment().endOf('month'),
      monthlyAttendance: [],
      selectedMonth: [],
      isLoadingData: false,
      selectedAttendance: null,
      isComponentModalActive: false,
      selectedUserId: null,
      selectedUserName: null,
    }
  },
  methods: {
    ...mapActions({
      actionFetchMonthlyAttendance: 'attendance/fetchMonthlyAttendance',
    }),

    formatTime(str, format) {
      return moment(moment.utc(str).toDate()).format(format)
    },

    selectAttendance(attendance, row) {
      this.selectedAttendance = attendance
      this.selectedUserName = row.name
      this.selectedUserId = row.id

      this.isComponentModalActive = true
    },

    closeSidebar() {
      this.isComponentModalActive = false
      this.selectedAttendance = null
    },

    async selectMonth(val) {
      // start of moment
      this.fromDate = new moment(val).startOf('month')
      this.toDate = new moment(val).endOf('month')
      this.selectedMonth = [new Date(this.fromDate), new Date(this.toDate)]

      this.momentDateRange = []
      this.momentDateRange = this.generateMomentDateRange(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
      await this.fetchMonthlyAttendance(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
    },
    formatMinute(durationInMinute, hourFormat = 'h', minuteFormat = 'm') {
      let hourF = hourFormat
      let minuteF = minuteFormat

      if (durationInMinute == null) {
        return null
      }
      if (durationInMinute < 60) {
        return durationInMinute.toString() + minuteF
      }
      //*  count hour
      let hour = Math.floor(durationInMinute / 60)
      //* count minute
      let minute = durationInMinute % 60
      return hour.toString() + hourF + ' ' + minute.toString() + minuteF
    },

    async increaseMonth() {
      this.fromDate = this.fromDate.add('1', 'month').startOf('month')
      this.toDate = this.toDate.add('1', 'month').endOf('month')

      this.selectedMonth = [new Date(this.fromDate), new Date(this.toDate)]

      this.momentDateRange = []
      this.momentDateRange = this.generateMomentDateRange(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
      await this.fetchMonthlyAttendance(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
    },
    async decreaseMonth() {
      this.fromDate = this.fromDate.subtract('1', 'month').startOf('month')
      this.toDate = this.toDate.subtract('1', 'month').endOf('month')

      this.selectedMonth = [new Date(this.fromDate), new Date(this.toDate)]

      this.momentDateRange = []
      this.momentDateRange = this.generateMomentDateRange(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
      await this.fetchMonthlyAttendance(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
    },

    getClassCircleMarkWithColor(date, attendanceData) {
      let baseClass = 'circle-mark'

      let log = this.getLogByStartDate(date, attendanceData)
      let classname = this.determineClassDuration(
        log.durationPerDay,
        log.isTimeOff
      )
      return baseClass + ' ' + classname
    },

    // parameter date format : 'YYYY-MM-DD'
    getLogByStartDate(date, attendanceData) {
      let filtered = attendanceData.filter((e) => e.date === date)
      let filteredTemp = filtered.filter((e) => e.is_time_off === false)

      if (filteredTemp.length > 0) {
        return filteredTemp[0]
      }
      if (filtered.length > 0) {
        return filtered[0]
      }

      return null
    },

    convertDurationToMinute(durationPerDay) {
      let split = durationPerDay.split(':') // split it at the colons

      // Hours are worth 60 minutes.
      return +split[0] * 60 + +split[1]
    },

    // return class(string) that affect background color of circle mark
    //durationPerDay : hh:mm:ss format
    // isTimeOff : boolean
    determineClassDuration(durationPerDay, isTimeOff) {
      if (isTimeOff) return 'not-active'

      // convert all to minute
      let totalMinute = this.convertDurationToMinute(durationPerDay)

      let hoveredClass = "circle-mark-cursor-pointer "
      // less than or equal 8 hour
      if (totalMinute <= 8 * 60) {
        return hoveredClass + 'is-normal'
      }
      if (totalMinute <= 9 * 60) {
        return hoveredClass + 'is-overwork'
      }
      if (totalMinute <= 10) {
        return  hoveredClass + 'extremely-overwork'
      }
      return hoveredClass + 'burn-out'
    },
    formatDate(date, format = 'YYYY-MM-DD') {
      return moment(moment.utc(date).toDate()).format(format)
    },
    determineProfilePicture(profilePictureUrl, gender) {
      if (!profilePictureUrl) {
        if (gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return profilePictureUrl
    },
    generateMomentDateRange(fromDate, toDate, unit = 'days') {
      let startDate = moment(fromDate)
      let endDate = moment(toDate)
      let diff = endDate.diff(startDate, unit)
      let range = []
      for (let i = 0; i <= diff; i++) {
        range.push(moment(fromDate).add(i, unit))
      }
      return range
    },
    async fetchMonthlyAttendance(startDate, endDate) {
      this.isLoadingData = true
      try {
        this.monthlyAttendance = await this.actionFetchMonthlyAttendance({
          startDate,
          endDate,
          isLoadAllEmployments: this.isLoadAllEmployments,
        })
      } catch (err) {
        showToast('Failed to fetch data', 'is-danger', 'is-bottom')
        console.log(err)
      }
      this.isLoadingData = false
    },

    // To generate attendance report
    async generateAttendanceReport() {
      const month = moment(this.selectedMonth[0]).format('M')
      const year = moment(this.selectedMonth[0]).format('YYYY')

      try {
        let response = await axios.get(`/api/space-roketin/attendance/export`, {
          headers: {
            'Content-Disposition': 'attachment; filename=AttendanceReport.xlsx',
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'blob',
          params: {
            month,
            year,
          },
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'AttendanceReport.xlsx')
        document.body.appendChild(link)
        link.click()

        showToast('Export success', 'is-success', 'is-bottom')
      } catch (err) {
        showToast('Failed to fetch data', 'is-danger', 'is-bottom')
      }
    },

    // To generate WFO report
    async generateWFOReport() {
      const start_date = moment(this.selectedMonth[0]).format('YYYY-MM-DD')
      const end_date = moment(this.selectedMonth[1]).format('YYYY-MM-DD')

      try {
        let response = await axios.get(`/api/space-roketin/wfo/export`, {
          headers: {
            'Content-Disposition': 'attachment; filename=WFOReport.xlsx',
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'blob',
          params: {
            start_date,
            end_date,
          },
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'WFOReport.xlsx')
        document.body.appendChild(link)
        link.click()

        showToast('Export success', 'is-success', 'is-bottom')
      } catch (err) {
        showToast('Failed to fetch data', 'is-danger', 'is-bottom')
      }
    },
  },
  mounted() {
    // generate date range for table purpose
    this.momentDateRange = this.generateMomentDateRange(
      this.fromDate.format('YYYY-MM-DD'),
      this.toDate.format('YYYY-MM-DD')
    )
    // load data
    this.fetchMonthlyAttendance(
      this.fromDate.format('YYYY-MM-DD'),
      this.toDate.format('YYYY-MM-DD')
    )
    // create first and last date of the month
    this.selectedMonth = [
      new Date(this.fromDate.format('YYYY-MM-DD')),
      new Date(this.toDate.format('YYYY-MM-DD')),
    ]
  },
}
</script>
